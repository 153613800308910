<template>
  <div class="center" :style="(check ==2?'background:rgba(56, 68, 156, 1);':null)">
    <div class="center-head" :style="(check ==2?'height:228px;background: linear-gradient(360deg, rgba(246,246,246,0) 0%, #796EFF 100%);':null)">
      <div class="center-head-banner">
        <div @click="checkteble(0)">
          <p :class="check ==0 ? 'check' : null">{{unitauthorize('余额')}}兑彩贝</p>
        </div>
        <div @click="checkteble(1)">
          <p :class="check ==1 ? 'check' : null">彩贝兑换</p>
        </div>
        <div @click="checkteble(2)">
          <p :class="check ==2 ? 'check' : null">彩贝宝箱</p>
        </div>
      </div>
      <div class="center-money" v-if="(check ==0 || check ==1)">
        <p>{{ check ==0 ? "我的"+unitauthorize('余额') : "彩贝余额" }}</p>
        <p>
          {{ money
          }}<span
            >{{ check==0 ? unitauthorize('元') : ""
            }}<img
              v-if="(check==1)"
              src="https://ppyos.xijiuyou.com/202205/msg-icon.jpg"
              alt=""
          /></span>
        </p>
        <div @click="caibeirecord">
          彩贝兑换明细
          <img
            src="https://ppyos.xijiuyou.com/202205/right-arrow.jpg"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="Treasure" v-if="(check ==2)">
      <Treasure></Treasure>
    </div>
    <div class="content-list" v-if="(check ==0)">
      <p class="title">兑换彩贝</p>
      <div class="list-arr">
        <div
          v-for="(item, index) in coinConfig.coinConfig"
          :key="index"
          :class="item.id === ids ? 'list-arr-check' : ''"
          @click="configcheck(item)"
          :style="money < item.chCoin?'opacity: 0.3;':''"
        >
          <p
          >
            {{item.formatted}} <img src="https://ppyos.xijiuyou.com/202205/msg-icon.jpg" alt="">
          </p>
          <p>
            需{{item.chCoin}}{{unitauthorize('元余额')}}
          </p>
          <p v-if="item.id === ids">
            <img
              src="https://ppyos.xijiuyou.com/202205/check-icon.jpg"
              alt=""
            />
          </p>
        </div>
      </div>
    </div>
    <div v-if="(check == 1)" class="checktable">
      <div  class="checktable-hea" >
        <div class="checktable-head" :style="`width:${tabs.length*60}px;`">
          <div v-for="(item , index) in tabs" :key="index" @click="listcheack(item,tabs)">
            <p :class="code === item.code?'checktable-head-size':''">{{item.title}}</p>
            <img v-if="code === item.code" src="https://ppyos.xijiuyou.com/202206/simle-icon.png" alt="">
          </div>
        </div>
      </div>
      <div class="checktable-content">
        <div class="checktable-content-list" @click="exchangecaibei(item)" v-for="(item , index) in  tabslist" :key="index" :style="item.cost > money || item.payMoney > money?'opacity: 0.49;':''">
          <div class="checktable-content-list-top">
            <div>
              <p v-if="item.prizeProps || item.note || item.goodsProps">{{item.goodsProps?item.goodsProps.day?'':'¥':''}}<span>{{item.note?item.note:(item.prizeProps.money?item.prizeProps.money:item.prizeProps.price)}}{{item.goodsProps?item.goodsProps.day?item.goodsProps.day:'':''}}{{item.goodsProps?item.goodsProps.day?'日':'':''}}</span></p>
              <!-- <p v-else-if="item.goodsProps"><span>{{item.goodsProps.day?item.goodsProps.day+"日":''}}</span></p> -->
              <p>{{item.payMoneyFormat?'全国话费':item.title}}</p>
            </div>
            <img v-if="item.goodsProps || item.icon" :src="item.icon?item.icon:item.goodsProps.icon" alt="">
          </div>
          <div class="checktable-content-list-bottom">
            <p><span>{{item.payMoneyFormat?item.payMoneyFormat:item.formatted}}</span> <img src="https://ppyos.xijiuyou.com/202205/msg-icon.jpg" alt=""></p>
            <p>立即兑换</p>
          </div>
        </div>
      </div>
      <div class="checktable-content-empty" v-if="tabslist.length==0">
        <div>
          <img src="https://ppyos.xijiuyou.com/202206/empty-list.jpg" alt="">
        </div>
        <p>暂无可兑</p>
      </div>
    </div>
    <div class="explain-right" v-if="(check ==1)">
      <p>兑换说明</p>
      <p v-html="tips"></p>
    </div>
    <div class="explain" v-if="(check == 0)">
      <p>兑换说明</p>
      <p v-html="coinConfig.exchangeTips"></p>
    </div>
    <div class="dui-bottom" v-if="(check == 0)">
      <div class="dui-bottom-top">
        <div>
          <span>{{
            check == 0
              ? checkitem.formatted
                ? checkitem.formatted
                : 0
              : checkitem.chCoin
              ? checkitem.chCoin + unitauthorize('元')
              : unitauthorize('0元')
          }}</span>
          <img
            v-if="(check ==0)"
            src="https://ppyos.xijiuyou.com/202205/msg-icon.jpg"
            alt=""
          />
        </div>
        <div @click="confirms">
          {{ ids == -1 ? "请选择兑换额度" : "确认兑换" }}
        </div>
      </div>
      <p>
        <span>需</span
        ><span>{{
          check ==0
            ? checkitem.chCoin
              ? checkitem.chCoin
              : 0
            : checkitem.formatted
            ? checkitem.formatted
            : 0
        }}</span
        >{{ check==0 ? unitauthorize('元') : ""
        }}<img
          v-if="(check == 1)"
          src="https://ppyos.xijiuyou.com/202205/msg-icon.jpg"
          alt=""
        />
      </p>
    </div>
    <xwpopup :isshowpopup="profit" @close="closeprofit()" :hiddenClose="false">
      <div class="xwpopup">
        <img
          :src="
            check == 1
              ? 'https://ppyos.xijiuyou.com/202205/fish-success-small.jpg'
              : 'https://ppyos.xijiuyou.com/202205/fish-success.jpg'
          "
          alt=""
        />
        <p>{{ check == 0 ? "您已成功兑换" : "您已成功兑换红包，已到"+unitauthorize('余额') }}</p>
        <p>
          {{ check == 1 ? checkitem.chCoin : checkitem.formatted
          }}{{ check == 1 ? unitauthorize('元') : ""
          }}<img
            v-if="(check == 0)"
            src="https://ppyos.xijiuyou.com/202205/msg-icon.jpg"
            alt=""
          />
        </p>
        <p @click="closeprofit">好的</p>
      </div>
    </xwpopup>
    <xwpopup :isshowpopup="popup" @close="closepopup()" :hiddenClose="true">
      <div class="popupscontent" @click.stop.self="closepopup()">
        <div class="popups" v-if="notification.goodsProps && notification.prizeProps">
          <img :src="notification.goodsProps.bigIcon" alt="">
          <p class="popups-money"><span>{{notification.prizeProps.money}}</span>{{notification.prizeProps.unit}}{{notification.prizeProps.title}}</p>
          <div>
            <p>兑换品说明</p>
            <p v-html="notification.goodsProps.tips"></p>
            <div></div>
          </div>
          <div>
            <p>需 <!--<span>100</span>万-->{{notification.formatted}} <img src="https://ppyos.xijiuyou.com/202205/msg-icon.jpg" alt=""></p>
            <p @click="succ" :style="notification.cost > money?'background:#DDDDDD;':''">{{notification.cost > money?'彩贝不足':'确认兑换'}}</p>
          </div>
        </div>
      </div>
    </xwpopup>
    <xwpopup :isshowpopup="voucher" @close="closevoucher()" :hiddenClose="false">
      <div class="voucher" v-if="notification.goodsProps && notification.prizeProps">
        <img src="https://ppyos.xijiuyou.com/202206/light-bg.jpg" alt="">
        <img :src="notification.goodsProps.bigIcon" alt="">
        <p>您已成功兑换</p>
        <p>{{notification.title}} <span>{{notification.prizeProps.money}}</span>{{notification.prizeProps.unit}}</p>
        <p>{{notification.prizeProps.code === 'prizeMoneySendService'  || notification.prizeProps.code === 'prizeSgamePrivilegeAdFree'?'':'卡密将在1分钟内发放至您的消息中心，请及时查收使用'}}</p>
        <p @click="succvoucher">{{notification.prizeProps.code === 'prizeMoneySendService' || notification.prizeProps.code === 'prizeSgamePrivilegeAdFree'?'好的':'查看卡密'}}</p>
      </div>
    </xwpopup>
    <xwpopup :isshowpopup="phones" @close="closephones()" :hiddenClose="true">
      <div class="phones">
        <p>提示</p>
        <p>为确保账户安全，兑换商品需要绑定手机号码，是否去绑定？</p>
        <div>
          <p @click="closephones()">以后再说</p>
          <p @click="gotophone()">现在去绑定</p>
        </div>
      </div>
    </xwpopup>
  </div>
</template>
<script>
import { Toast } from "vant";
import caibei from "../../api/caibei";
import telnumber from "../../api/telnumber";
import xwpopup from "../../components/Popupbox.vue";
import unit from "../../utils/unit";
import Treasure from "./Treasure.vue";
export default {
  components: {
    xwpopup,
    Treasure
  },
  data() {
    return {
      voucher:false,
      popup:false,
      check: 0,
      money: "",
      ids: -1,
      coinConfig: {},
      checkitem: {},
      profit: false,
      exchanged:true,
      phones:false,
      tips:"",
      tabs:[],
      code:"total",
      tabslist:[],
      notification:{},
    };
  },
  mounted() {
      this.check= this.$route.query.table;
    if (this.check === "0") {
      this.balance();
      this.configs(0);
      document.title =this.unitauthorize('余额')+"兑彩贝";
    }else if(this.check==='1'){
        this.exchangebalance();
        document.title = "彩贝兑换";
    }else if(this.check==='2'){
      document.title = "彩贝宝箱";
    }else{
      this.balance();
      this.configs(0);
      this.check = 0;
    }
    this.conchshopping();
    
  },
  methods: {
    unitauthorize(name){
      return unit.authorize(name)
    },
    succvoucher(){
      if(this.notification.prizeProps.code !== 'prizeMoneySendService' && this.notification.prizeProps.code !==  'prizeSgamePrivilegeAdFree'){
        try{
          window.location.href = "paopaoyu://app/page/user/message";
        }catch(e){
          console.log(e)
        }
      }else{
        this.closevoucher();
        if (this.check == 0) {
          this.balance();
          this.configs(0);
        }else if(this.check == 1) {
          this.exchangebalance();
        }
      }
    },
    configsphone(){
      telnumber.configs({payInfo:'coinPayInfoService'}).then(res=>{
        this.tabslist.push(...res.data.recharges);
      })
    },
    succ(){
      if(this.notification.cost > this.money) {
        Toast("余额不足");
        return false;
      }
      caibei.conchbuyItem(this.notification.goodId).then(res=>{
        this.popup =false;
        if(res.status === 0){
          this.voucher = true;
        }else if(res.status === 60002){
          this.phones = true;
        }
      })
    },
    exchangecaibei(item){
      if(item.payMoney > this.money || item.cost > this.money) {
        Toast("余额不足");
        return false;
      }
      // 加上一个判断
      if(undefined !=item.prizeProps &&  item.prizeProps.code === "prizeFuluDirectSendService"){
        localStorage.setItem("videoVip",JSON.stringify(item));
        this.$router.push({
          name:"videoVip",
        })
      }
      caibei.checkPhoneBind().then(res=>{
        if(res.code === 60002) {
          this.phones = true;
          return false;
        }
        if(item.configId){
          this.$router.push({
            name:'chargescaibei',
            query:{
              configId:item.configId,
              caibei:this.money
            }
          })
        }
        this.notification = item;
        this.popup = true;
      })
    },
    listcheack(item,nver){
      const that =this;
      that.tabslist = [];
      that.code = item.code
      if(item.code === "total"){
        nver.map(res=>{
          that.tabslist.push(...res.items)
        })
        telnumber.configs({payInfo:'coinPayInfoService'}).then(res=>{
          this.tabslist.push(...res.data.recharges);
        })
      }else if(item.code === "available"){
        nver.map(res=>{
          res.items.map(_res=>{
            if(_res.cost <= that.money){
              that.tabslist.push(_res)
            }
          })
        })
        telnumber.configs({payInfo:'coinPayInfoService'}).then(res=>{
          res.data.recharges.map(_res=>{
            if(_res.payMoney <= that.money){
              that.tabslist.push(_res)
              
            }
          })
        })
      }else if(item.code === "redpack"){
        that.tabslist = item.items
      }else if(item.code === "jdecard"){
        that.tabslist = item.items
      }else if(item.code === "tmailcard"){
        that.tabslist = item.items
      }else if(item.code === "creditCharge"){
        telnumber.configs({payInfo:'coinPayInfoService'}).then(res=>{
          that.tabslist.push(...res.data.recharges);
        })
      }else if(item.code === "smartgame"){
        that.tabslist = item.items
      }else if(item.code === "videoVip"){
        that.tabslist = item.items
      }
    },
    conchshopping(){
      caibei.conchshopping().then(res=>{
        this.tips = res.data.tips;
        this.tabs = res.data.tabs;
        res.data.tabs.map(_res=>{
          this.tabslist.push(..._res.items);
        })
        this.configsphone();
      })
    },
    closephones(){
      this.phones = false;
    },
    gotophone(){
      this.closephones();
      try{
        window.location.href = "paopaoyu://app/page/user/bind/phone";
      }catch(e){
        console.log(e)
      }
    },
    closevoucher(){
      this.voucher = false;
    },
    closepopup(){
      this.popup = false;
    },
    closeprofit() {
      this.profit = !this.profit;
      this.exchanged = true;
    },
    confirms() {
      if (this.ids == -1) {
        Toast("请选择兑换额度");
        return false;
      }
      if(this.exchanged){
        this.exchanged = false; 
        Toast.allowMultiple();
        const Toasts = Toast.loading({
          message: '加载中...',
          forbidClick: true,
        });
        caibei.requestExchange(this.ids).then((res) => {
          Toasts.clear();
          this.ids = -1;
          if (res.status === 0) {
            this.profit = true;
            this.balance();
            // if (!this.check) {
              
            // } else {
            //   this.exchangebalance();
            // }
          }else{
            this.exchanged = true;
          }
        });
      }
      
    },
    configcheck(item) {
        if (this.money >= item.chCoin) {
          this.ids = item.id;
          this.checkitem = item;
        }
        // if (this.money >= item.coin) {
        //   this.ids = item.id;
        //   this.checkitem = item;
        // }
      
    },
    configs(type) {
      caibei.configs(type).then((res) => {
        this.coinConfig = res.data;
      });
    },
    balance() {
      caibei.balance().then((res) => {
        this.money = res.data.balance;
      });
    },
    exchangebalance() {
      caibei.exchangebalance().then((res) => {
        this.money = res.data.balance;
      });
    },
    caibeirecord() {
      this.$router.push({
        name: "caibeirecord",
      });
    },
    checkteble(res) {
      this.check = res;
      this.ids = -1;
      this.checkitem = {};
      if (this.check == 0) {
        this.balance();
        this.configs(0);
        document.title = this.unitauthorize('余额')+"兑彩贝";
      } else  if (this.check == 1){
        this.exchangebalance();
        document.title = "彩贝兑换";
        // this.configs(1);
      }else{
        document.title = "彩贝宝箱";
      }
    },
  },
};
</script>
<style lang="less" scoped>
.center {
  width: 100%;
  min-height: 100vh;
  background: #f6f6f6;
  padding-bottom: 120px;
  .content-list {
    background: #ffffff;
    border-radius: 20px 20px 0px 0px;
    margin-top: 10px;
    padding-top: 14px;
    padding-bottom: 16px;
    div {
      padding: 13px 18px;
    }
    p:nth-child(1) {
      display: flex;
      font-size: 20px;
      font-family: DINOffcPro-Medi, DINOffcPro;
      font-weight: normal;
      color: #444444;
      span {
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #444444;
      }
      img {
        width: 24px;
        height: 24px;
        margin-left: 6px;
        margin-top: 2px;
      }
    }
    p:nth-child(2) {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(68, 68, 68, 0.5);
      margin-top: 5px;
      display: flex;
      align-items: center;
      img {
        width: 16px;
        height: 16px;
        margin-left: 2px;
      }
    }
    .title {
      padding-left: 12px!important;
      font-size: 16px!important;
      font-family: PingFangSC-Semibold, PingFang SC!important;
      font-weight: 600!important;
      color: #444444!important;
      margin-bottom: 14px!important;
    }
    .list-arr {
      display: flex;
      justify-content: space-between;
      padding: 0 12px;
      flex-wrap: wrap;
      .grey {
        opacity: 0.4;
      }
      div {
        width: calc(50% - 4px);
        height: 80px;
        border-radius: 10px;
        border: 1px solid #dddddd;
        margin-bottom: 10px;
        position: relative;
        p:nth-child(3) {
          position: absolute;
          width: 30px;
          height: 26px;
          background: #00a3ff;
          border-radius: 0px 10px 0px 10px;
          right: -2px;
          top: -2px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 14px;
            height: 10px;
          }
        }
      }
      &-check {
        border: 2px solid #00a3ff !important;
      }
    }
  }
  .explain {
    padding: 20px 18px;
    p:nth-child(1) {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #888888;
      margin-bottom: 10px;
    }
    p:nth-child(2) {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #888888;
    }
  }
  &-head {
    background: linear-gradient(360deg, #F6F6F6 0%, #796EFF 100%);
    padding-top:10px;
    &-banner {
      display: flex;
      justify-content: space-evenly;
      padding: 4px 5px;
      margin: 0 auto;
      margin-bottom: 10px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #444444;
      background: #FFFFFF;
      border-radius: 18px;
      width: calc(100% - 60px);
      align-items: center;
      div {
        text-align: center;
        width: 33%;
        p{
          height: 28px;
          line-height: 28px;
        }
        .check {
          height: 28px;
          background: linear-gradient(180deg, #51D5FF 0%, #009CFF 100%);
          border-radius: 14px;
          text-align: center;
          line-height: 28px;
          color: #ffffff;
        }
        p:nth-child(2) {
          width: 19px;
          height: 3px;
          background: #444444;
          border-radius: 2px;
          margin: 0 auto;
          margin-top: 14px;
        }
      }
    }
  }
  &-money {
    width: calc(100% - 24px);
    margin: 0 auto;
    background: #ffffff;
    border-radius: 10px;
    margin-top: 8px;
    position: relative;
    text-align: center;
    padding-top: 19px;
    p:nth-child(1) {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(68, 68, 68, 0.7);
    }
    p:nth-child(2) {
      font-size: 28px;
      font-family: DINOffcPro-Medi, DINOffcPro;
      font-weight: normal;
      color: #444444;
      margin-top: 10px;
      margin-bottom: 18px;
      span {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #444444;
        img {
          width: 24px;
          height: 24px;
          margin-left: 6px;
        }
      }
    }
    div {
      width: calc(100% - 56px);
      margin: 0 auto;
      height: 31px;
      line-height: 31px;
      border-top: 1px dashed #dddddd;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #444444;
      img {
        width: 5px;
        height: 8px;
        margin-left: 4px;
      }
    }
  }
  .dui-bottom {
    height: 93px;
    background: #ffffff;
    width: 100%;
    position: fixed;
    bottom: 0;
    padding-top: 10px;
    box-shadow: 0px -2px 5px rgba(0 ,0 ,0,  0.03);
    p {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #444444;
      padding-left: 24px;
      margin-top: 5px;
      img {
        width: 16px;
        height: 16px;
      }
      span {
        font-size: 18px;
        font-family: DINOffcPro-Medi, DINOffcPro;
        font-weight: normal;
        color: #444444;
      }
      span:nth-child(2) {
        margin: 0 3px;
      }
      span:nth-child(1) {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #444444;
      }
    }
    &-top {
      display: flex;
      justify-content: space-between;
      padding: 0 12px;
      box-sizing: border-box;
      div:nth-child(1) {
        width: calc(50% - 30px);
        height: 48px;
        background: #f6f6f6;
        border-radius: 10px;
        display: flex;
        align-items: center;
        padding-left: 14px;
        img {
          width: 24px;
          height: 24px;
          margin-left: 4px;
        }
      }
      div:nth-child(2) {
        width: calc(50% - 30px);
        height: 48px;
        background: linear-gradient(
          270deg,
          rgba(106, 200, 255, 1) 0%,
          rgba(209, 153, 255, 1) 100%
        );
        box-shadow: inset 0px 2px 0px 0px rgba(255, 246, 229, 0.39);
        border-radius: 26px;
        font-size: 15px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .xwpopup {
    width: 300px;
    height: 195px;
    background: #ffffff;
    border-radius: 15px;
    margin-top: 200px;
    padding-top: 20px;
    position: relative;
    img {
      width: 100%;
      position: absolute;
      left: 0;
      top: -89px;
    }
    p:nth-child(2) {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #444444;
      margin: 0 auto 25px;
    }
    p:nth-child(3) {
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0;
      margin-bottom: 25px;
      font-size: 28px;
      font-family: DINOffcPro-Medi, DINOffcPro;
      font-weight: normal;
      color: #444444;
    }
    p:nth-child(4) {
      width: 197px;
      height: 44px;
      background: linear-gradient(270deg, #6ac8ff 0%, #d199ff 100%);
      box-shadow: inset 0px 2px 0px 0px rgba(255, 246, 229, 0.39);
      border-radius: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      margin: 0 auto;
    }
    p {
      img {
        width: 24px;
        height: 24px;
        margin: 0;
        position: relative;
        top: 0;
        margin-left: 4px;
      }
    }
  }
  .checktable{
    width: 100%;
    border-radius: 20px 20px 0 0;
    margin-top: 10px;
    background: #ffffff;
    padding: 0 12px;
    .checktable-hea{
      width: 100%;
      overflow-x: auto;
    }
    &-head{
      display: flex;
      justify-content: space-between;
      padding: 14px 12px 0;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #444444;
      margin-bottom: 10px;
      width: 100%;
      &-size{
        font-size: 16px;
        font-family: SourceHanSansCN-Bold, PingFang SC;
        color: #444444;
        margin-top: -2px;
      }
      div{
        text-align: center;
        img{
          width: 16px;
          margin-top: -4px;
        }
      }
    }
    &-content{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      
      &-empty{
        text-align: center;
        padding: 40px 0;
        div{
          width: 100%;
          img{
            width: 123px;
          }
        }
        p{
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          margin-top: 28px;
        }
      }
      &-list{
        width: calc(50% - 5px);
        height: 114px;
        background: url("https://ppyos.xijiuyou.com/202206/rect-bg.png") no-repeat;
        background-size: 100% 100%;
        padding: 14px 12px;
        box-sizing: border-box;
        margin-bottom: 14px;
        position: relative;
        // &-h{
        //   position:absolute;
        //   width: 100%;
        //   height: 100%;
        //   left: 0;
        //   top: 0;
        //   border-radius: 10px;
        // }
        &-top{
          display: flex;
          justify-content: space-between;
          align-items: center;
          img{
            width: 34px;
            height: 34px;
          }
          div{
            font-size: 14px;
            font-family: DINOffcPro-Medi, DINOffcPro;
            font-weight: normal;
            color: #444444;
            span{
              font-size: 26px;
              font-family: DINOffcPro-Medi, DINOffcPro;
              font-weight: normal;
              color: #444444;
            }
            p:nth-child(2){
              font-size: 13px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #444444;
            }
          }
        }
        &-bottom{
          display: flex;
          width: 100%;
          height: 27px;
          background: linear-gradient(270deg, #6AC8FF 0%, #D199FF 100%);
          border-radius: 14px;
          justify-content: space-between;
          margin-top: 10px;
          padding: 2px 8px 2px 2px;
          align-items: center;
          p:nth-child(1){
            width: 55%;
            height: 23px;
            background: #FFFFFF;
            border-radius: 14px 0px 0px 14px;
            font-size: 13px;
            font-family: DINOffcPro-Medi, DINOffcPro;
            font-weight: normal;
            color: #444444;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
              width: 16px;
              height: 16px;
              margin-left: 2px;
            }
            span{
              margin-top: 2px;
            }
          }
          p:nth-child(2){
            font-size: 12px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #FFFFFF;
          }
        }
      }
      
    }
  }
  .explain-right{
    padding: 20px 12px;
    background: #ffffff;
    margin-top: 10px;
    p:nth-child(1) {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #888888;
      margin-bottom: 10px;
    }
    p:nth-child(2) {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #888888;
    }
  }
  .popupscontent{
    width: 100%;
    height: 100%;
  }
  .popups{
    position: fixed;
    width: 100%;
    background: #ffffff;
    bottom: 0;
    border-radius: 15px 15px 0px 0px;
    padding: 0 12px 18px;
    img{
      margin-top: -62px;
      width: 158px;
      margin-bottom: 17px;
    }
    &-money{
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #444444;
      margin-bottom: 21px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      span{
        font-size: 24px;
        font-family: DINOffcPro-Medi, DINOffcPro;
        font-weight: normal;
        color: #444444;
        margin-top: 3px;
      }
    }
    div:nth-child(3){
      width: 100%;
      max-height: 278px;
      overflow-y: auto;
      background: rgba(246, 246, 246, 1);
      text-align: left;
      padding: 20px 14px;
      margin-bottom: 20px;
      div{
        position: absolute;
        left: 12px;
        bottom: 85px;
        background: linear-gradient(180deg, rgba(251, 251, 251, 0) 0%, #F6F6F6 100%);
        margin: 0;
        width: calc(100% - 24px);
      }
      p:nth-child(2){
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(68, 68, 68, 0.6);
        line-height: 25px;
      }
    }
    div:nth-child(4){
      display: flex;
      justify-content: space-between;
      align-items: center;
      p:nth-child(1){
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #444444;
        display: flex;
        align-items: center;
        span{
          font-size: 24px;
          font-family: DINOffcPro-Medi, DINOffcPro;
          font-weight: normal;
          color: #444444;
          margin: -4px 4px 0;
        }
        img{
          width: 24px;
          height: 24px;
          margin: 0;
          margin-left: 5px;
        }
      }
      p:nth-child(2){
        width: 147px;
        height: 48px;
        background: linear-gradient(270deg, #6AC8FF 0%, #D199FF 100%);
        box-shadow: inset 0px 2px 0px 0px rgba(255, 246, 229, 0.39);
        border-radius: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
      }
    }
  }
}
.voucher{
  width: 305px;
  background: #ffffff;
  margin-top: 150px;
  position: relative;
  padding-top: 22px;
  border-radius: 15px;
  padding-bottom: 30px;
  img:nth-child(1){
    width: calc(100% - 36px);
    position: absolute;
    top: -79px;
    left: 18px;
  }
  img:nth-child(2){
    width: 90px;
    position: absolute;
    top: -50px;
    left: calc(50% - 45px);
  }
  p:nth-child(3){
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #444444;
    margin-bottom: 20px;
  }
  // p:nth-child(4){
  //   font-size: 16px;
  //   font-family: PingFangSC-Semibold, PingFang SC;
  //   font-weight: 600;
  //   color: #444444;
  //   line-height: 16px;
  //   margin-bottom: 16px;
  //   span{
  //     font-size: 24px;
  //     font-family: DINOffcPro-Medi, DINOffcPro;
  //     font-weight: normal;
  //     color: #444444;
  //   }
  // }
  p:nth-child(4){
    width: calc(100% - 36px);
    height: 39px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: normal;
    color: #797AE5;
    display: flex;
    align-items: center;
    span{
      font-size: 24px;
      font-family: DINOffcPro-Medi, DINOffcPro;
      font-weight: normal;
      color: #797AE5;
      margin-top: 1px;
    }
    margin-bottom: 20px;
  }
  p:nth-child(5){
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #888888;
    margin-bottom: 10px;
  }
  p:nth-child(6){
    width: 197px;
    height: 44px;
    background: linear-gradient(270deg, #6AC8FF 0%, #D199FF 100%);
    box-shadow: inset 0px 2px 0px 0px rgba(255, 246, 229, 0.39);
    border-radius: 22px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }
}
.phones{
  width: calc(100% - 70px);
  padding: 20px 23px 30px;
  background: #ffffff;
  border-radius: 15px;
  margin-top: 200px;
  p:nth-child(1){
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
  }
  p:nth-child(2){
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    margin: 21px 0;
  }
  div{
    display: flex;
    justify-content: space-around;
    p:nth-child(1){
      width: 120px;
      height: 44px;
      background: #EEEEEE;
      border-radius: 27px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #444444;
    }
    p:nth-child(2){
      width: 120px;
      height: 44px;
      border-radius: 27px;
      background: linear-gradient(270deg, #6AC8FF 0%, #D199FF 100%);
      box-shadow: inset 0px 2px 0px 0px rgba(255, 246, 229, 0.39);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #444444;
      margin: 0;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
    }
  }
}
.Treasure{
  margin-top: -160px;
}
</style>